exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-affiliate-disclosure-js": () => import("./../../../src/pages/affiliate-disclosure.js" /* webpackChunkName: "component---src-pages-affiliate-disclosure-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dmca-js": () => import("./../../../src/pages/dmca.js" /* webpackChunkName: "component---src-pages-dmca-js" */),
  "component---src-pages-email-confirm-js": () => import("./../../../src/pages/email-confirm.js" /* webpackChunkName: "component---src-pages-email-confirm-js" */),
  "component---src-pages-email-success-js": () => import("./../../../src/pages/email-success.js" /* webpackChunkName: "component---src-pages-email-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-templates-post-list-js": () => import("./../../../src/templates/post-list.js" /* webpackChunkName: "component---src-templates-post-list-js" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-grammar-alphabet-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/grammar/alphabet/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-grammar-alphabet-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-grammar-prepositions-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/grammar/prepositions/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-grammar-prepositions-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-phrases-greetings-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/phrases/greetings/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-phrases-greetings-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-phrases-introductions-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/phrases/introductions/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-phrases-introductions-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-colors-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/colors/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-colors-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-days-of-the-week-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/days-of-the-week/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-days-of-the-week-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-directions-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/directions/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-directions-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-family-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/family/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-family-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-months-of-the-year-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/months-of-the-year/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-months-of-the-year-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-numbers-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/numbers/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-numbers-index-mdx" */),
  "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-seasons-index-mdx": () => import("./../../../src/templates/single-post.js?__contentFilePath=/opt/build/repo/posts/vocabulary/seasons/index.mdx" /* webpackChunkName: "component---src-templates-single-post-js-content-file-path-opt-build-repo-posts-vocabulary-seasons-index-mdx" */),
  "component---src-templates-tag-posts-js": () => import("./../../../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */)
}

